import './Appointments.css';

import {
  Assignment as AssignmentIcon,
  DeleteDocument,
  EventAcceptedTentatively,
  EventDeclined,
  HealthCalendar,
  ViewList as ViewListIcon,
} from '../../icons';
import { Grid, Tooltip, debounce } from '@material-ui/core';
import React, { Component } from 'react';

import Add from '@material-ui/icons/Add';
import ApiInvoker from '../../api/ApiInvoker';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import AppointmentItemsDetailModal from './AppointmentItemsDetailModal';
import Assignment from '@material-ui/icons/Assignment';
import Authority from '../../util/Authority';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import ComponentDisabledWithTooltip from '../ComponentDisabledWithTooltip';
import Cookies from 'universal-cookie';
import Copy from '@material-ui/icons/FileCopy';
import CustomDialog from '../CustomDialog/index';
import CustomInput from '../CustomInput/CustomInput';
import CustomSweetAlert from '../CustomSweetAlert';
import DateInput from '../DateInput';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import DoneIcon from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { InfoOutlined } from '@material-ui/icons';
import ItemsRequest from './ItemsRequest';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Table from '../TableFull/Table';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();
const dateFormatServer = config.getDateToServer();

class AppointmentsPractice extends Component {
  constructor(props) {
    super(props);
    this.processItemsRequest = this.processItemsRequest.bind(this);

    const { t } = props;
    const proximityList = [
      { id: -1, value: '' },
      { id: 1, value: t('appointment.table.filters.red') },
      { id: 2, value: t('appointment.table.filters.yellow') },
      { id: 3, value: t('appointment.table.filters.green') },
    ];
    const scheduleStatusList = [
      { id: -1, value: '' },
      { id: 'PENDING_APPROVAL', value: t('scheduleDetail.state.PENDING_APPROVAL') },
      { id: 'REJECTED', value: t('scheduleDetail.state.REJECTED') },
      { id: 'APPROVED', value: t('scheduleDetail.state.APPROVED') },
      { id: 'IN_PROGRESS', value: t('scheduleDetail.state.IN_PROGRESS') },
      { id: 'DONE', value: t('scheduleDetail.state.DONE') },
      { id: 'NOT_ATTENDED', value: t('scheduleDetail.state.NOT_ATTENDED') },
      { id: 'WITHOUT_ATTENTION', value: t('scheduleDetail.state.WITHOUT_ATTENTION') },
      { id: 'CANCELLED', value: t('scheduleDetail.state.CANCELLED') },
      { id: 'FINISHED_SYSTEM', value: t('scheduleDetail.state.FINISHED_SYSTEM') }
    ];
    const customerStatusList = [
      { id: '', value: '' },
      { id: 'active', value: t('customer.active') },
      { id: 'inactive', value: t('customer.inactive') }
    ];
    const appointmentStatusList = [
      { id: '', value: '' },
      { id: 'CREATED', value: t('appointment.table.status.CREATED') },
      { id: 'SCHEDULES_ASSIGNED', value: t('appointment.table.status.SCHEDULES_ASSIGNED') },
      { id: 'PARTIAL_ASSIGNED', value: t('appointment.table.status.PARTIAL_ASSIGNED') },
      { id: 'CANCELED', value: t('appointment.table.status.CANCELLED') },
      { id: 'ENDED', value: t('appointment.table.status.ENDED') },
      { id: 'OVERDUE', value: t('appointment.table.status.OVERDUE') },
      { id: 'APROVEED', value: t('status.appointmentItemRequest.APROVEED') }
    ];

    const appointmentStatusPractice = [
      { id: '', value: '' },
      { id: 'CREATED', value: t('appointment.table.status.CREATED') },
      { id: 'APROVEED', value: t('status.appointmentItemRequest.APROVEED') },
      { id: 'REJECT', value: t('status.appointmentItemRequest.REJECTED') },
      { id: 'SCHEDULES_ASSIGNED', value: t('appointment.table.status.SCHEDULES_ASSIGNED') },
      { id: 'CANCELED', value: t('appointment.table.status.CANCELLED') },
    ];
    const dayAndWeekList = [
      { id: '', value: '' },
      { id: 'DAY', value: t('recurrenceTypes.day') },
      { id: 'WEEK', value: t('recurrenceTypes.week') },
      { id: 'MONTH', value: t('recurrenceTypes.month') },
    ];

    this.appointmentStatusList = [
      { id: '', value: '' },
      { id: 'CREATED', value: t('appointment.table.status.CREATED') },
      { id: 'SCHEDULES_ASSIGNED', value: t('appointment.table.status.SCHEDULES_ASSIGNED') },
      { id: 'PARTIAL_ASSIGNED', value: t('appointment.table.status.PARTIAL_ASSIGNED') },
      { id: 'CANCELED', value: t('appointment.table.status.CANCELLED') },
      { id: 'ENDED', value: t('appointment.table.status.ENDED') },
      { id: 'OVERDUE', value: t('appointment.table.status.OVERDUE') },
      { id: 'APROVEED', value: t('status.appointmentItemRequest.APROVEED') }
    ];

    this.dayAndWeekList = [
      { id: '', value: '' },
      { id: 'DAY', value: t('recurrenceTypes.day') },
      { id: 'WEEK', value: t('recurrenceTypes.week') },
      { id: 'MONTH', value: t('recurrenceTypes.month') },
    ];

    this.appointmentStatusPractice = [
      { id: '', value: '' },
      { id: 'CREATED', value: t('appointment.table.status.CREATED') },
      { id: 'APROVEED', value: t('status.appointmentItemRequest.APROVEED') },
      { id: 'REJECT', value: t('reject') },
      { id: 'SCHEDULES_ASSIGNED', value: t('appointment.table.status.SCHEDULES_ASSIGNED') },
      { id: 'CANCELED', value: t('appointment.table.status.CANCELLED') },
    ];

    this.state = {
      practiceTypes: [],
      isPracticeTypesLoaded: false,
      suppliers: [],
      supplierNames: [],
      selectedSupplierName: '',

      appointments: [],
      itemsRequest: [],
      page: 0,
      dayAndWeekList,

      showItemRequest: false,
      loading: false,
      loadingSearch: false,
      pageSize: 25,
      pageSizeOriginal: 25,
      scheduleStatusList,
      appointmentStatusList,
      appointmentStatusPractice,
      customerStatusList,
      proximityList,
      filterModules: '',
      filterProfessionals: '',
      filterStatus: '',
      id: '',
      appointmentId: '',
      filterTools: '',
      // filterColors,
      dateFrom: '',
      filter: {
        practiceTypeId: '',
      },
      dateTo: '',
      currentFilterColor: [],
      company_partnership_id: null,
      color: 'true',
      checkedInput: false,
      currentUserId: localStorage.getItem('user_id'),
      dialogCopyApointment: false,
      busy: false,
      chips: [],
      page: 0,
      originalquery: [],
      showFilters: false,
      showPractices: false,
      selectedAppointmentId: null,
      chipsFilters: [],
      chips_original: [],
      filterShow: {
        practiceTypeId: '',
      },
      customersList: [],
      selectedAppointment: {},
      changeRequests: false,
      customerNameSelected: null,
      selectedCancelAppointment: null,
      third_parties: [],
      lender: null,
      creatorUsers: [],
      selectedCreatorUser: null,
    }
    this.myRefStartDate = React.createRef();
    this.myRefEndDate = React.createRef();
    this.myRefCustomerId = React.createRef();
  }

  processItemsRequest = () => {
    const { itemsRequest, page, pageSize } = this.state;
    const processedAppointments = itemsRequest
      .slice(0, (page + 1) * pageSize)
      .map(this.appDataFormater);
    this.setState({ appointments: processedAppointments });
  }

  getCookies() {
    const cookies = new Cookies();
    const filter = cookies.get("items-request-filter");

    if (filter != null) {
      let page = cookies.get("items-request-filter_page");
      if (page == null) {
        page = 0;
      } else {
        page = parseInt(page);
      }

      const patientName = cookies.get("appointment_patient_name");
      if (patientName != null) {
        this.getCustomerstAPI(patientName);
      }
      this.setState({ filter, filterShow: filter, customerNameSelected: patientName, page }, () => {
        this.getAppointments(true);
      });
    } else {
      let page = cookies.get("items-request-filter_page");
      if (page == null) {
        page = 0;
      } else {
        page = parseInt(page);
      }
      this.setState({ page }, () => {
        this.getAppointments(true);
      });
    }
  }

  componentWillMount() {
    const company_partnership_id = localStorage.getItem('company_partnership_id');
    const is_patient = localStorage.getItem('is_patient') == 'true';
    this.setState({ company_partnership_id, is_patient });
    this.getPracticeTypes();
    this.getCookies();
    this.getPracticeTypes();
    this.getModuleTypes();
    this.getProvincies();
    this.getPartnership();
    this.getCreatorUsers();
  }

  getPracticeTypes() {
    if (!this.state.isPracticeTypesLoaded) {
      AppointmentApiInvoker.getPracticeTypes(data => {
        this.setState({ 
          practiceTypes: this.formatPracticeTypes(data),
          isPracticeTypesLoaded: true
        }, () => {
          if (this.state.filter.practiceTypeId) {
            this.getPartnership();
          }
        });
      }, null);
    }
  }

  formatPracticeTypes = (practiceTypes) => {
    return practiceTypes.map(pt => ({
      id: pt.practiceTypeId,
      value: pt.name,
      estimatedDuration: pt.estimatedDuration,
    }));
  }

  getToolSuppliersData() {
    if (this.state.supplierNames.length === 0) {
      ApiInvoker.getToolSuppliers(data => {
        if(data) {
          const suppliers = this.formatSuppliers(data);
          this.setState({
            suppliers,
            supplierNames: suppliers.map(s => ({ id: s.id, value: s.name }))
          })
        }
      }, null);
    }
  }

  formatSuppliers(data) {
    return data.map(d => ({
      id: d.productSuppliersId,
      name: d.name,
      code: d.code
    }));
  }


  getModuleTypes() {
    AppointmentApiInvoker.getPackagesAll(data => {
      if (data.length) {
        this.setState({ package: data });
      }
    }, null);
  }

  componentWillReceiveProps(next) {
    if (next.appointments) {
      this.reduceAppointments(next.appointments);
      if (this.state.page > 0) {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.onGetAppointments([]);
  }

  getAcceptRejectItems = (appointmentId) => {
    const { appointments } = this.state;
    const selectedAppointment = appointments.find((a) => a.appointmentId === appointmentId);
    this.setState({
      appointmentId,
      selectedAppointment,
      showItemRequest: true,
      showConfirmAcceptItemRequest: false,
      showConfirmRejectItemRequest: false,
    });
  }

  getParamsDatesAndCustomer(k, value) {
    if (k === 'startDate' || k === 'endDate') {
      const endDate = moment(value);
      value = endDate.format(dateFormatServer);
    }
    if (k === 'customerStatus') {
      k = "activeCustomers";
      value = value === 'active' ? "true" : "false";
    }
    return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
  }

  buildParamsGetAppointments(load) {
    console.log("buildParamsGetAppointments called with load:", load);
    let { filter, page, pageSize, pageSizeOriginal } = this.state;
    console.log("Current filter:", filter);
  
    if (load && page !== 0) {
      pageSize = pageSizeOriginal * (page);
      page = 0;
    }
  
    const filterParams = [
      'appointmentId',
      'customerId',
      'startDate',
      'endDate',
      'customerStatus',
      'scheduleStatus',
      'lender',
      'practiceTypeId',
      'appointmentStatusPractice',
      'selectedSupplierName',
      'creatorUserId',
    ];
  
    const params = filterParams
      .filter(key => filter[key] != null && filter[key] !== '')
      .map(key => {
        let value = filter[key];
        let paramKey = key;
  
        switch(key) {
          case 'startDate':
          case 'endDate':
            value = moment(value).format(dateFormatServer);
            break;
          case 'customerStatus':
            paramKey = 'activeCustomers';
            value = value === 'active' ? 'true' : 'false';
            break;
          case 'lender':
            paramKey = 'companyIdPartnership';
            break;
          case 'appointmentStatusPractice':
            paramKey = 'practiceStatus';
            break;
          case 'selectedSupplierName':
            paramKey = 'productSuppliersId';
            break;
          case 'creatorUserId':
            paramKey = 'personId';
            break;
          default:
            break;
        }
  
        return `${encodeURIComponent(paramKey)}=${encodeURIComponent(value)}`;
      })
      .filter(Boolean) 
      .join('&');
  
    const paginationParams = `page=${page}&pageSize=${pageSize}`;
    console.log("Built params:", params);
    
    return params ? `${params}&${paginationParams}` : paginationParams;
  }

  getAppointments = (isNewSearch = true) => {
    console.log("getAppointments called with isNewSearch:", isNewSearch);
    const { itemsRequest } = this.state;
    this.setState({ loading: true });
    const params = this.buildParamsGetAppointments(!isNewSearch);
    console.log("Params sent to API:", params);
    AppointmentApiInvoker.getItemsRequestFilter(params, data => {
      console.log("API response:", data);
      this.setState({ loading: false, loadingSearch: false });
      if (data) {
        const newItemsRequest = isNewSearch ? data : [...itemsRequest, ...data];
        newItemsRequest.sort((a, b) => (b.appointmentId - a.appointmentId));
  
        this.setState({ itemsRequest: newItemsRequest }, () => {
          this.processItemsRequest();
        });
      } else {
        this.setState({ itemsRequest: [], appointments: [] });
      }
    }, (error) => {
      console.error('** error getItemsRequestFilter', error);
      this.setState({ loading: false, loadingSearch: false });
    });
  }

  formatProvincies = (provincies) => provincies.map(e => ({
    id: e.provinceId,
    value: e.name,
  }));


  getItemRequest = (appointmentId) => {
    this.setState({ showPractices: true, selectedAppointmentId: appointmentId });
  }

  getProvincies = () => {
    ApiInvoker.getAllProvinces(prov => {
      const provinces = this.formatProvincies(prov);
      this.setState({ provinces });
    }, (error) => console.error('** error getAllProvinces', error));
  }

  renderEndDate(daysLeft, rejected, schedulesPending, appointmentStatus) {
    const { t } = this.props;
    switch (true) {
      case daysLeft >= 11 && daysLeft <= 15:
        return (
          <>
            <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${t('appointment.table.days')} `}>
              <span><AssignmentIcon className="warning svgIcon" /></span>
            </Tooltip>
            {rejected}
            {schedulesPending}
          </>
        );
      case daysLeft >= 6 && daysLeft <= 10:
        return (
          <>
            <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${t('appointment.table.days')} `}>
              <span><AssignmentIcon className="warning svgIcon" /></span>
            </Tooltip>
            {rejected}
            {schedulesPending}
          </>
        );
      case daysLeft <= 5 && daysLeft > 0:
        return (
          <>
            <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${(daysLeft === 1) ? t('appointment.table.day') : t('appointment.table.days')} `}>
              <span><AssignmentIcon className="danger svgIcon" /></span>
            </Tooltip>
            {rejected}
            {schedulesPending}
          </>
        );
      case daysLeft === 0:
        return (
          <>
            <Tooltip className="toolTipEdit" title={t('appointment.table.remaining.today')}>
              <span><AssignmentIcon className="danger svgIcon" /></span>
            </Tooltip>
            {rejected}
            {schedulesPending}
          </>
        );
      case appointmentStatus === 'SCHEDULES_ASSIGNED' && daysLeft < 0:
        return (
          <>
            <Tooltip className="toolTipEdit" title={t('appointment.table.remaining.end')}>
              <span><AssignmentIcon className="default svgIcon" /></span>
            </Tooltip>
            {rejected}
            {schedulesPending}
          </>
        );
      default:
        return <>{rejected}{schedulesPending}</>
    }
  }

  endDateFormater(endDate, hasSchedulesRejected, hasSchedulesPending, appointmentStatus) {
    const { t } = this.props;
  
    const now = moment();
    const endDateF = moment(endDate);
    let daysLeft = -1;
    if (appointmentStatus === 'SCHEDULES_ASSIGNED' || appointmentStatus === 'CREATED') {
      daysLeft = Math.ceil(endDateF.diff(now, 'days', true));
    }
    let rejected = '';
    let schedulesPending = '';
  
    if (hasSchedulesRejected) {
      rejected = <Tooltip className="toolTipEdit" title={t('appointment.table.rejected')}>
        <span><EventDeclined className="danger svgIcon" /></span>
      </Tooltip>;
    }
    if (hasSchedulesPending) {
      schedulesPending = <Tooltip className="toolTipEdit" title={t('appointment.table.pending')}>
        <span><EventAcceptedTentatively className="warning svgIcon" /></span>
      </Tooltip>;
    }
  
    return this.renderEndDate(daysLeft, rejected, schedulesPending, appointmentStatus);
  }

  getAppointmentStatus(a, t, notFoundMessage = t('recurrenceTypes.status.not.found')) {
    const status = this.appointmentStatusList.find(
      (status) => status.id === a.appointmentItemRequestStatus
    );
    return status?.value || notFoundMessage;
  }

  getAppointmentStatusDAY(a, t, notFoundMessage = t('recurrenceTypes.not.found')) {
    const status = this.dayAndWeekList.find(
      (status) => status.id === a.recurrenceType
    );
    return status?.value || notFoundMessage;
  }

  appDataFormater = (a, index) => {
    const { t } = this.props;
    const appointmentDateState = this.endDateFormater(a.endDate, a.hasSchedulesRejected, a.hasSchedulesPending, a.appointmentStatus);
    
    const data = {
      employee: { personId: a?.responsableEmployee?.personId },
      // appointmentItemRequestStatus: a.appointmentItemRequestStatus,
      appointmentItemRequestStatus: this.getAppointmentStatus(a, t),
      appointmentId: a.appointmentId,
      affiliateId: a.affiliateId,
      date: moment(a.createdDateTime || a.startDate).format(dateFormat),
      createdDateTime: a.createdDateTime || a.startDate,
      customerId: a.customerId || `${a.customerFirstName} ${a.customerLastName}`,
      hasSchedulesRejected: a.hasSchedulesRejected || false,
      hasItemsPending: a.hasItemsPending || (a.appointmentStatus === 'CREATED'),
      hasItemsConfirmed: a.hasItemsConfirmed || (a.appointmentStatus !== 'CREATED'),
      locationName: a.locationName || '',
      provinceName: a.provinceName || '',
      financierName: a.companyFinancier || a.partnershipName,
      appointmentDateState,
      name: a.name || `${a.customerLastName} ${a.customerFirstName}`,
      startDate: moment(a.startDate).format(dateFormat),
      endDate: moment(a.endDate).format(dateFormat),
      appStatus: t(`appointment.table.status.${a.appointmentStatus}`) || t('appointment.table.status.DEFAULT'),
      actions: this.renderActionComponent(a, index),
      compareEndDate: moment(a.endDate),
      createdDateCompare: moment(a.createdDateTime || a.startDate).format(dateFormatServer),
      practiceTypeName: a.practiceTypeName,
      codPractice: a.codPractice,
      partnerCode: a.partnerCode,
      partnershipName: a.partnershipName,
      toolId: a.toolId,
      recurrenceType: a.recurrenceType,
      quantity: a.quantity,
      suppliersCode: a.suppliersCode,
      suppliersName: a.suppliersName,
      // frecuencia: `${a.recurrenceType} / ${a.quantity}`,
      frecuencia: `${this.getAppointmentStatusDAY(a, t)} / ${a.quantity}`,
    };
  
    return data;
  }
  reduceAppointments = (appointments) => {
    if (appointments.length) {
      const data = appointments.map((app, index) => this.appDataFormater(app, index));
      const oldAppointments = this.state.appointments.filter(a => a.actions == null);
      oldAppointments.push(...data);

      let uniques = oldAppointments.filter((v, i, a) => a.findIndex(t => (t.appointmentId === v.appointmentId)) === i);

      uniques.sort((a, b) => (a.appointmentId < b.appointmentId && 1) || -1)

      let size = ((this.state.page + 1) * this.state.pageSize);
      let un = uniques.slice(0, size);
      this.setState({ appointments: un, originalquery: un });
    }
  }

  openCancelAppointment = (appointmentId) => {
    this.setState({ selectedCancelAppointment: appointmentId },
      () => {
        this.setState({ showConfirmCancelAppointment: true });
      }
    );
  }

  cancelAppointmentAction = () => {
    const body = {
      "reason": "Cancell Created appointment",
      "appointmentDetailIds": [],
    }
    AppointmentApiInvoker.cancelAppointment(this.state.selectedCancelAppointment, body, () => {
      this.setState({ showConfirmationCancelAppointment: true, showConfirmCancelAppointment: false })
    }, () => {
      this.setState({ showConfirmationCancelAppointment: true, showConfirmCancelAppointment: false })
    });
  }

  redirectToCancelView = (appointmentId) => {
    browserHistory.push({
      pathname: `/appointments/${appointmentId}/cancel`,
    });
  }

//
getCreatorUsers = () => {
  AppointmentApiInvoker.getEmployeesWithPractices(
    (data) => {
      const formattedUsers = this.formatCreatorUsers(data);
      console.log("Formatted creator users:", formattedUsers);
      this.setState({ creatorUsers: formattedUsers });
    },
    (error) => {
      console.error('Error fetching creator users:', error);
    }
  );
}

  formatCreatorUsers = (users) => {
    return users.map(user => ({
      id: user.personId,
      value: `${user.lastName} ${user.firstName}`
    }));
  }

  handleCreatorUserChange = (value) => {
    console.log("handleCreatorUserChange called with value:", value);
    this.setState(prevState => ({
      filter: { ...prevState.filter, creatorUserId: value },
      filterShow: { ...prevState.filterShow, creatorUserId: value },
      selectedCreatorUser: value
    }), () => {
      console.log("State after handleCreatorUserChange:", this.state.filter);
    });
  }

  getPartnership = () => {
    AppointmentApiInvoker.getCompanyPartnershipModules(data => {
      const third_parties = this.buildPartnershipData(data);
      this.setState({ third_parties });
    }, () => {
      this.setState({ third_parties: [] });
    });
  }

  buildPartnershipData = (data) => {
    const partnership = data.map(p => ({
      id: p.companyIdPartnership.companyId,
      value: p.companyIdPartnership.name,
    }));
  
    const id = parseInt(localStorage.getItem('itlg_default_company_id'));
    const value = localStorage.getItem('itlg_default_company_name');
    const partnershipArray = [{ id, value }, ...partnership];
    return partnershipArray;
  }
  
  renderActionComponent = (a, index) => {
    const { onSelectAppointmentList, t } = this.props;
    return (
      <div className="appointments-actions">
        <Tooltip title={t('appointment.viewAppointment')}>
          <span>
            <Button
              simple
              justIcon
              color="primary"
              authority="appointment_view,appointment_view_related"
              id={`button-view-${index}`}
              onClick={() => {
                onSelectAppointmentList(a.appointmentId);
                browserHistory.push({
                  state: { mode: 'view' },
                  pathname: `/solicitudes/${a.appointmentId}`
                });
              }}
            ><Assignment /></Button>
          </span>
        </Tooltip>
        {(Authority.hasPermission('appointment_edit_agenda') && (a.appointmentStatus === 'SCHEDULES_ASSIGNED' || a.appointmentStatus === 'PARTIAL_ASSIGNED')) &&
          <Tooltip title={t('appointment.editCalendar')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                authority="appointment_edit_agenda"
                id={`button-edit-${index}`}
                onClick={() => {
                  onSelectAppointmentList(a.appointmentId);
                  browserHistory.push({
                    state: { mode: 'edit', authority: 'appointment_edit_agenda' },
                    pathname: `/appointments/${a.appointmentId}/edit/agenda`
                  });
                }}
              ><HealthCalendar /></Button>
            </span>
          </Tooltip>
        }

        {Authority.hasPermission('appointment_item_request_acept_reject') && ((a.appointmentStatus === 'CREATED' || a.appointmentStatus === 'PARTIAL_ASSIGNED') &&
          a.hasItemsPending === true) &&
          <Tooltip title={t('appointment.actions.acceptReject')}>
            <span>
              <Button
                simple
                justIcon
                color="primary"
                authority="appointment_item_request_acept_reject"
                id={`button-items-${index}`}
                onClick={() => this.getAcceptRejectItems(a.appointmentId)}
              >
                <span className="icon-with-overlay">
                  <ViewListIcon className="principal-icon" />
                  <DoneIcon className="overlay-icon" />
                </span>
              </Button>
            </span>
          </Tooltip>
        }

        {Authority.hasPermission('appointment_edit') && (this.state.company_partnership_id == null && a.appointmentStatus !== 'CREATED' && a.appointmentStatus !== 'CANCELLED' && a.appointmentStatus !== 'ENDED')
          && a.appointmentStatus !== 'OVERDUE' &&
          <Tooltip title={t('appointment.actions.edit')}>
            <span>
              <Button
                simple
                justIcon
                color="success"
                authority="appointment_edit"
                id={`button-edit-app-${index}`}
                onClick={() => {
                  onSelectAppointmentList(a.appointmentId);
                  browserHistory.push({
                    state: { mode: 'edit' },
                    pathname: `/solicitudes/${a.appointmentId}`
                  });
                }}
              ><Edit /></Button>
            </span>
          </Tooltip>
        }
        {Authority.hasPermission('appointment_edit') && (this.state.company_partnership_id == null && a.appointmentStatus === 'CREATED') && (
          <>
            <Tooltip title={t('appointment.actions.edit')}>
              <span>
                <Button
                  simple
                  justIcon
                  color="success"
                  authority="appointment_edit"
                  id={`button-edit-agenda-${index}`}
                  onClick={() => {
                    onSelectAppointmentList(a.appointmentId);
                    browserHistory.push({
                      state: { mode: 'edit' },
                      pathname: `/appointments/${a.appointmentId}/edit/agenda`,
                    });
                  }}
                ><Edit /></Button>
              </span>
            </Tooltip>
            {Authority.hasPermission('appointment_cancel') && this.state.company_partnership_id == null &&
              <Tooltip title={t('appointment.actions.cancel')}>
                <span>
                  <Button
                    simple
                    justIcon
                    color="danger"
                    authority="appointment_cancel"
                    id={`button-cancel-${index}`}
                    onClick={() => this.redirectToCancelView(a.appointmentId)}
                  ><DeleteDocument className="svg-cancel-agenda" /></Button>
                </span>
              </Tooltip>
            }
          </>
        )}
        {Authority.hasPermission('appointment_assign') && ((a.appointmentStatus === 'CREATED' || a.appointmentStatus === 'PARTIAL_ASSIGNED') &&
          a.hasItemsConfirmed === true) &&
          <Tooltip title={t('appointment.actions.assignAgenda')}>
            <span>
              <Button
                simple
                justIcon
                color="violet"
                authority="appointment_assign"
                id={`button-list-${index}`}
                onClick={() => {
                  this.props.onSelectAppointmentList(a.appointmentId)
                  browserHistory.push({
                    state: { mode: 'assign_agenda' },
                    pathname: `/appointments/${a.appointmentId}/edit/agenda`,
                  })
                }}
              ><HealthCalendar className="svg-assign-agenda" /></Button>
            </span>
          </Tooltip>
        }
        {Authority.hasPermission('appointment_cancel') && (a.appointmentStatus !== 'CANCELLED' && a.appointmentStatus !== 'CREATED' &&
          a.appointmentStatus !== 'OVERDUE' && a.appointmentStatus !== 'ENDED' && this.state.company_partnership_id == null) &&
          <Tooltip title={t('appointment.actions.cancel')}>
            <span>
              <Button
                simple
                justIcon
                color="danger"
                authority="appointment_cancel"
                id={`button-cancel-app-${index}`}
                onClick={() => this.redirectToCancelView(a.appointmentId)}
              ><DeleteDocument className="svg-cancel-agenda" /></Button>
            </span>
          </Tooltip>
        }
        {Authority.hasPermission('end_schedule_by_system') && ((a.appointmentStatus === 'SCHEDULES_ASSIGNED' || a.appointmentStatus === 'OVERDUE') &&
          this.state.company_partnership_id == null) &&
          <Tooltip title={t('schedule.finished.title')}>
            <span>
              <Button
                simple
                justIcon
                color="danger"
                authority="end_schedule_by_system"
                id={`button-finished-system-${index}`}
                onClick={() => {
                  browserHistory.push({
                    state: { mode: 'view', action: "finished_system" },
                    pathname: `/solicitudes/${a.appointmentId}`,
                  });
                }}
              ><EventAvailableIcon className="svg-cancel-agenda" /></Button>
            </span>
          </Tooltip>
        }
        <Tooltip title={t('appointment.actions.list.practice')}>
          <span>
            <Button
              simple
              justIcon
              color="success"
              id={`button-request-${index}`}
              onClick={() => {
                this.getItemRequest(a.appointmentId);
              }}
            ><DnsOutlinedIcon /></Button>
          </span>
        </Tooltip>
      </div>
    )
  }

  formatCustomers = (customers) => {
    if (!customers.length) {
      return [];
    }
    return customers.map(e => ({
      id: e.personId,
      value: `${e.lastName} ${e.firstName}`,
    })).sort((a, b) => a.value.localeCompare(b.value));
  }

  getCustomerstAPI = (text) => {
    PersonApiInvoker.getCustomersOptions(text, data => {
      const customersList = this.formatCustomers(data);
      this.setState({ customersList });
    }, (error) => console.error('** error getCustomersOptions', error));
  }

  getCustomersAPIDebounced = (value) => debounce(this.getCustomerstAPI(value), 500);

  clearCustomerFieldSearch() {
    this.setState((prevState) => (
      {
        ...prevState,
        filter: {
          ...prevState.filter,
          customerId: '',
        },
        filterShow: {
          ...prevState.filterShow,
          customerId: '',
        },
        customersList: [],
      }
    ));
  }

  handleCustomerOptions(event) {
    if (event?.target?.value == undefined) {
      return;
    }
    const value = (event?.target?.value);
    const isValue = (event?.target) && (value && value.length >= 3);
    if (!event) {
      this.clearCustomerFieldSearch();
    }
    if (isValue) {
      this.setState({ customersList: [] });
      this.getCustomersAPIDebounced(value);
    }
    const isInvalidValueAndCustomers = event?.target?.value?.length < 3 && this.state.customersList.length > 0;
    if (isInvalidValueAndCustomers) {
      this.clearCustomerFieldSearch();
    }
  }

  onFocusCustomerFilter(event) {
    event.persist();
    if (!(this.state?.filter?.customerId)) {
      this.clearCustomerFieldSearch();
    }
  }

  handleValue(value, state) {
    this.setState(prevState => ({
      filter: { ...prevState.filter, [state]: value },
      filterShow: { ...prevState.filterShow, [state]: value },
    }), () => {
      if (state === 'customerId') {
        let v = this.state.customersList.filter(e => e.id == value);
        if (v.length > 0) {
          this.setState({ customerNameSelected: v[0].value });
        }
      }
      if (state === 'practiceTypeId' && value) {
        this.getPartnership();
      }
    });
  }

  addAppointment = () => {
    browserHistory.push('/nueva-solicitud');
  }


  sortDate = (a, b) => {
    const a1 = moment(a, dateFormat).valueOf();
    const b1 = moment(b, dateFormat).valueOf();
    return a1 - b1;
  }

  toggleCopy = () => {
    this.setState((prevState) => ({ dialogCopyApointment: !prevState.dialogCopyApointment, busy: false }));
  }

  onChangeItemsRequest = (changeRequests) => this.setState({ changeRequests });

  toggleItemRequest = () => {
    this.setState({ showItemRequest: false });
    if (this.state.changeRequests) {
      this.setState({ loading: true, loadingSearch: true, appointments: [] }, () => {
        this.getAppointments();
      });
    }
  }

  checkAppointment = () => {
    const { appointment_copy, dialogCopyApointment } = this.state;
    this.setState({ busy: true });
    AppointmentApiInvoker.getAppointment(
      appointment_copy,
      () => {
        this.props.onSelectAppointmentList(-1);
        this.props.onSelectAppointmentObject(null);
        browserHistory.push({
          state: { mode: 'copy' },
          pathname: `/appointments/${appointment_copy}/renew/agenda`,
        });
      }, () => {
        this.setState({ dialogCopyApointment: !dialogCopyApointment, alertWarningCopy: true, busy: false });
      }
    );
  }
  
  filtersSearch = () => {
    console.log("filtersSearch called");
    this.props.onCleanAppointments();
    this.setState((prevState) => {
      const newFilter = {
        ...prevState.filter,
        creatorUserId: prevState.filter.creatorUserId
      };
      console.log("New filter in filtersSearch:", newFilter);
      return {
        filter: newFilter,
        loading: true,
        loadingSearch: true,
        page: 0,
        appointments: [],
        itemsRequest: [],
      };
    }, () => {
      console.log("State after filtersSearch:", this.state.filter);
      let cookies = new Cookies();
      cookies.set("items-request-filter", this.state.filter);
      cookies.set("appointment_patient_name", this.state.customerNameSelected);
      this.getAppointments();
    });
  }

  showMore = () => {
    this.setState((prev) => ({
      page: prev.page + 1,
      loading: true,
    }), () => {
      let cookies = new Cookies();
      cookies.set("items-request-filter_page", this.state.page);
      this.getAppointments(false); 
    });
  }

  cleanSearch = () => {
    this.props.onCleanAppointments();
    this.setState({
      filter: {},
      filterShow: {},
      page: 0,
      loading: false,
      loadingSearch: false,
      customersList: [],
      appointments: [],
      itemsRequest: [],
      customerNameSelected: null,
      lender: null,
      third_parties: [],
      selectedCreatorUser: null,
      creatorUsers: [],
    }, () => {
      let cookies = new Cookies();
      cookies.remove("items-request-filter");
      cookies.remove("items-request-filter_page");
      this.getAppointments();
      this.myRefStartDate.current.handleBlur('');
      this.myRefEndDate.current.handleBlur('');
      this.getPartnership();
      this.getCreatorUsers();
    });
  }

  perfomSuccessReload = () => {
    this.setState((prevState) => ({
      filter: prevState.filterShow,
      loading: true,
      page: 0,
      appointments: [],
      showConfirmationCancelAppointment: false,
    }), () => {
      this.getAppointments();
    });
  }

  render() {
    const { t } = this.props;
    const {
      alertWarningCopy, appointments,appointmentStatusPractice,
      busy, company_partnership_id, customersList, dialogCopyApointment, filter, filterShow,
      loading, loadingSearch, practiceTypes,   third_parties, lender,
       selectedAppointment, scheduleStatusList, selectedCancelAppointment, showConfirmCancelAppointment, showConfirmationCancelAppointment, showItemRequest,
    } = this.state;
    return (
      <Grid container className="appointments appointments-list">
        <Grid item xs={12}>
          <Card>
            <CardHeader icon className="filters-header" style={{ paddingBottom: 20 }}>
              <CardIcon color="secondary">
                <Assignment />
              </CardIcon>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} className="buttons-header appointment-header">
                  {company_partnership_id == null && !this.state.is_patient &&
                    <Tooltip title={t('appointment.new.newappointment')}>
                      <span>
                        <Button
                          className="add-button"
                          round
                          color="primary"
                          authority="appointment_new"
                          id="button-new-appo"
                          onClick={() => this.addAppointment()}
                        >
                          <Add className="appointments-button-icon" /> {t('appointment.create')}
                        </Button>
                      </span>
                    </Tooltip>
                  }
                  {company_partnership_id == null && !this.state.is_patient &&
                    <Tooltip title={t('appointment.copy')}>
                      <span>
                        <Button
                          className="copy-button"
                          round
                          color="warning"
                          authority="appointment_new"
                          id="button-copy-appo"
                          onClick={() => this.toggleCopy()}
                        >
                          <Copy className="appointments-button-icon" />
                        </Button>
                      </span>
                    </Tooltip>
                  }
                </Grid>
              </Grid>

              <Grid container className="appointments-filters">
                <Grid container item xs={12} sm={9} md={10} className="appointments-filters-row">
                  <Grid container item xs={12} className="filters-row first">
                    <Grid container item xs={12} sm={3}>
                      <Grid item xs={11}>
                        <CustomInput
                          className="filter-full no-padding"
                          labelText={t('appointment.find.appointmentId')}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={filter.appointmentId !== undefined ? filter.appointmentId : ''}
                          onChange={(value) => this.handleValue(value.target.value, 'appointmentId')}
                          errorText={t('error.required')}
                          id="input-appointmentId"
                        />
                      </Grid>
                    </Grid>
                    
                    {/* Fecha de fin */}
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        ref={this.myRefCustomerId}
                        className="no-padding"
                        label={t('appointment.find.customer')}
                        elements={customersList}
                        value={filter.customerId}
                        onSelectedValue={(value) => this.handleValue(value, 'customerId')}
                        onInputChange={(ev) => this.handleCustomerOptions(ev)}
                        freeSolo={true}
                        isWaitLetters={true}
                        getOptionSelected={option => option?.id || null}
                        onFocus={(ev) => this.onFocusCustomerFilter(ev)}
                        id="select-customerId"
                      />
                    </Grid>


                    {/* Fecha de inico y fin */}
                    <Grid container item xs={14} sm={3}>
                      <Grid container item xs={6} className="date-container startDate">
                        <DateInput
                          ref={this.myRefStartDate}
                          text={t('appointment.find.startDate')}
                          value={filterShow.startDate}
                          onChangeValue={(value) => this.handleValue(value, 'startDate')}
                          id="datetime-start"
                        />
                      </Grid>
                      <Grid container item xs={6} className="date-container endDate">
                        <DateInput
                          ref={this.myRefEndDate}
                          text={t('appointment.find.endDate')}
                          value={filterShow.endDate}
                          onChangeValue={(value) => this.handleValue(value, 'endDate')}
                          id="datetime-end"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} className="filters-row">
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.practice')}
                        elements={this.state.practiceTypes}
                        value={filterShow.practiceTypeId}
                        onSelectedValue={(value) => this.handleValue(value, 'practiceTypeId')}
                        id="select-practice-type"
                        onFocus={() => this.getPracticeTypes()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <SelectInput
                      className="date-filter no-padding"
                      label={t('appointment.find.customerStatus')}
                      elements={this.state.customerStatusList}
                      value={filterShow.customerStatus}
                      onSelectedValue={(value) => this.handleValue(value, 'customerStatus')}
                      id="select-appointment-status"
                      onFocus={() => this.getToolSuppliersData()}
                    />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.scheduleStatusPractice')}
                        elements={appointmentStatusPractice}
                        value={filterShow.appointmentStatusPractice}
                        onSelectedValue={(value) => this.handleValue(value, 'appointmentStatusPractice')}
                        id="select-appointment-status-practice"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <SelectInput
                      className="date-filter no-padding"
                      label={t('appointment.find.user.credator')}
                      elements={this.state.creatorUsers}
                      value={this.state.selectedCreatorUser}
                      onSelectedValue={this.handleCreatorUserChange}
                      id="select-creator-user"
                    />
                  </Grid>
                    <Grid item xs={12} sm={3}>
                    <SelectInput
                      className="date-filter no-padding"
                      label={t('appointment.new.schedule.practice.lender')}
                      elements={third_parties}
                      value={lender}
                      onSelectedValue={(value) => this.handleValue(value, 'lender')}
                      id="select-lender"
                    />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SelectInput
                        className="date-filter no-padding"
                        label={t('tools.new.title.name.proveedor')}
                        elements={this.state.supplierNames}
                        value={filterShow.selectedSupplierName}
                        onSelectedValue={(value) => this.handleValue(value, 'selectedSupplierName')}
                        id="select-supplier"
                        onFocus={() => this.getToolSuppliersData()}
                      />
                      
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <SelectInput
                        className="date-filter no-padding"
                        label={t('appointment.find.scheduleStatus')}
                        elements={scheduleStatusList}
                        value={filterShow.scheduleStatus}
                        onSelectedValue={(value) => this.handleValue(value, 'scheduleStatus')}
                        id="select-schedule-status"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                
                <Grid container item xs={12} sm={3} md={2} className="filters-actions-appointments">
                  <Grid container item justifyContent="flex-end" className="filters-row">
                    <Grid item xs={12} className="info-search">
                      <Tooltip aria-label="info" title={t('tooltipInfo.wizzard')} placement="left-start">
                        <InfoOutlined />
                      </Tooltip>
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <ComponentDisabledWithTooltip
                        tooltipText={t('reassignAppointments.buttonTooltip')}
                        disabled={false}
                        loading={false}
                        component={
                          <ButtonSpinner
                            id="button-search"
                            onClick={() => this.filtersSearch()}
                            label={t('appointment.find.find')}
                            labelLoading={t('appointment.find.find')}
                            loading={loadingSearch}
                            disabled={loading}
                            typeButton="submit"
                            color="primary"
                            block
                          />
                        }
                      />
                    </Grid>
                    <Grid item className="filters-actions" xs={12}>
                      <Button id="button-clean" block onClick={() => this.cleanSearch()} color="danger">
                        {t('appointment.find.clean')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardHeader>

             <CardBody className="appointments-content-body not-scroll-table filtrable-table">
              <Table
                filterable
                loading={loading}
                tableHeaderColor="primary"
                sortable
                tableHead={[
                  { Header: t('appointment.practice.table.status.practice'), id: 'appointmentItemRequestStatus', accessor: 'appointmentItemRequestStatus'},
                  { Header: t('appointment.practice.table.afild.number'), id: 'affiliateId', accessor: 'affiliateId' },
                  { Header: t('appointment.practice.table.paciente'), id: 'customerId', accessor: 'customerId' },
                  { Header: t('appointment.practice.table.code.practices'), id: 'codPractice', accessor: 'codPractice' },
                  { Header: t('appointment.practice.table.code.productos'), id: 'toolId', accessor: 'toolId' },
                  { Header: t('appointment.practice.table.name.practice'), id: 'practiceTypeName', accessor: 'practiceTypeName' },
                  { Header: t('appointment.practice.table.frecuencia'), id: 'frecuencia', accessor: 'frecuencia' },
                  { Header: t('appointment.practice.table.inicio.practice'), id: 'startDate', accessor: 'startDate', sortMethod: (a, b) => this.sortDate(a, b) },
                  { Header: t('appointment.practice.table.end.practice'), id: 'endDate', accessor: 'endDate', sortMethod: (a, b) => this.sortDate(a, b) },
                  { Header: t('appointment.practice.table.code.prestador'), id: 'partnerCode', accessor: 'partnerCode' },//
                  { Header: t('appointment.practice.table.code.provedor'), id: 'suppliersCode', accessor: 'suppliersCode' },
                  { Header: t('appointment.practice.table.prestador'), id: 'partnershipName', accessor: 'partnershipName' },//
                  { Header: t('appointment.practice.table.proveedor'), id: 'suppliersName', accessor: 'suppliersName' },
                  { Header: t('appointment.table.actions'), id: 'actions', accessor: 'actions', sortable: false, filterable: false },
                ]}
                tableData={this.state.appointments}
                colorsColls={['primary']}
                className="appointments-component -striped -highlight filtrable sticky"
                defaultPageSize={10000}
                showPaginationTop={false}
                showPaginationBottom={false}
              />
            </CardBody> 
          </Card>
        </Grid>

        {appointments.length > 0 &&
          <Grid item className="bottom" xs={12}>
            <ButtonSpinner
              onClick={() => this.showMore()}
              label={t('appointment.showmore.praciteces')}
              labelLoading={t('appointment.showmore')}
              loading={loading}
              typeButton="submit"
              block
              disabled={loading}
              className="button-gray"
              id="button-more"
            />
          </Grid>
        }

        <CustomDialog
          title={t('appointment.renew.title')}
          maxWidth="sm"
          open={dialogCopyApointment}
          onClose={() => this.toggleCopy()}
        >
          <div>
            <p className="appointmentRenewTitle">
              {t('appointment.renew.question')}
            </p>
            <div className="booking-errors-actions">
              <CustomInput
                className="filter-number"
                id="appointment_copy"
                type="number"
                formControlProps={{ fullWidth: false }}
                inputProps={{ autocomplete: "no" }}
                onChange={(e) => this.setState({ appointment_copy: e.target.value })}
              />
            </div>
          </div>
          <div className="content-actions">
            <ButtonSpinner
              onClick={() => this.checkAppointment()}
              disabled={busy}
              label={t('appointment.renew.acept')}
              labelLoading={t('common.saving')}
              loading={busy}
              typeButton="submit"
              color="primary"
              id="button-check"
            />
          </div>
        </CustomDialog>

        <CustomDialog
          maxWidth="md"
          open={showItemRequest}
          onClose={() => this.toggleItemRequest()}
          title={t('appointment.actions.acceptReject')}
        >
          <ItemsRequest
            selectedAppointment={{ appointmentId: selectedAppointment.appointmentId, endDate: selectedAppointment.endDate }}
            practiceTypes={practiceTypes}
            packages={this.state.package}
            onChangeItemsRequest={(a) => this.onChangeItemsRequest(a)}
          />
        </CustomDialog>

        {alertWarningCopy &&
          <CustomSweetAlert
            type="warning"
            onConfirm={() => this.setState({ dialogCopyApointment: true, alertWarningCopy: false })}
            confirmBtnCssClass="primary"
            confirmBtnText={t('appointment.new.accept')}
            showCancel={false}
            message={<p>{t('appointment.renew.renewWarning')}</p>}
          />
        }

        {this.state.showPractices &&
          <AppointmentItemsDetailModal
            appointmentId={this.state.selectedAppointmentId}
            onConfirm={(showPractices) => {
              this.setState((prevState) => ({ showPractices, selectedAppointmentId: !showPractices ? null : prevState.selectedAppointmentId })
              )
            }}
          />
        }

        {showConfirmCancelAppointment &&
          <CustomSweetAlert
            type="warning"
            onConfirm={this.cancelAppointmentAction}
            onCancel={() => this.setState({ showConfirmCancelAppointment: false })}
            confirmBtnCssClass="primary"
            cancelBtnCssClass="danger"
            title={t('appointment.created.cancel.title')}
            cancelBtnText={t('appointment.created.cancel.cancel')}
            confirmBtnText={t('appointment.created.cancel.confirm')}
            showCancel={true}
            message={<p>{t('appointment.created.cancel.message')} {selectedCancelAppointment}</p>}
          />
        }

        {showConfirmationCancelAppointment &&
          <CustomSweetAlert
            type="success"
            onConfirm={this.perfomSuccessReload}
            confirmBtnCssClass="primary"
            title={t('appointment.created.cancel.confirmation.title')}
            confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
            showCancel={false}
            message={<p>{selectedCancelAppointment} {t('appointment.created.cancel.confirmation.message')}</p>}
          />
        }
      </Grid>
   );
  }
}

AppointmentsPractice.propTypes = {
  t: PropTypes.func,
  onCleanAppointments: PropTypes.func.isRequired,
  onGetAppointments: PropTypes.func.isRequired,
  onSelectAppointmentList: PropTypes.func.isRequired,
  onSelectAppointmentObject: PropTypes.func.isRequired,
}

export default withTranslation()(AppointmentsPractice);
